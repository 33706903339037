import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-external'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, courses) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn
          },
          { text: '', style: 'margin_down', alignment: 'center' },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' },
          { text: '(' + vm.getFiscalYear(data.fiscal_year_id) + ')', style: 'hhh', alignment: 'center' }
        ]
        const revise = []
        revise.push([{ text: data.revise_count > 0 ? (vm.$t('elearning_config.revised') + '(' + dateFormat(data.created_at)) + ')' : '', style: 'td', alignment: 'right' }])
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: '*',
              body: revise
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        const allRows = []
        const headerRow = [
          { text: vm.$t('elearning_config.sl_no'), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.no_of_days'), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.no_of_batch'), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.jul') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.aug') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.sept') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.oct') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.nov') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.dec') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[0].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.jan') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.feb') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.mar') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.apr') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.may') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' },
          { text: vm.$t('elearning_config.jun') + '\n/' + vm.getFiscalYear(data.fiscal_year_id).split('-')[1].slice(-2), style: 'th', alignment: 'left' }
        ]
        allRows.push(headerRow)

        data.training_calendar_details.map((item, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), style: 'th', alignment: 'left' },
            { text: vm.getTrainingTilteName(item.training_title_id), style: 'td', alignment: 'left' },
            { text: vm.$n(item.no_of_days, { useGrouping: false }), style: 'td', alignment: 'left' },
            { text: vm.$n(item.no_of_batch, { useGrouping: false }), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 7 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 7 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 8 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 8 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 9 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 9 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 10 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 10 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 11 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 11 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 12 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 12 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 1 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 1 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 2 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 2 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 3 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 3 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 4 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 4 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 5 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 5 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' },
            { text: item.training_calendar_detail_days.flatMap((value, index) => ([(vm.getMonthValue(value.start_date) === 6 ? (vm.getValueX(value.start_date, value.end_date) === 1 ? (vm.$n(vm.getDateValue(value.start_date)) + '-' + vm.$n(vm.getDateValue(value.end_date)) + '\n') : vm.$t('elearning_config.start') + ':\n' + (vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) + '\n')) : ' ') + (vm.getMonthValue(value.end_date) === 6 && vm.getValueX(value.start_date, value.end_date) !== 1 ? vm.$t('elearning_config.end') + ':\n' + vm.$n(vm.getDateValue(value.start_date)) + vm.getMonthName(vm.getMonthValue(value.start_date)) + '--' + vm.$n(vm.getDateValue(value.end_date)) + vm.getMonthName(vm.getMonthValue(value.end_date)) : '')])), style: 'td', alignment: 'left' }
          ]
          allRows.push(rowItem)
        })

        pdfContent.push({
          table: {
            style: 'tableData',
            headerRows: 1, // Set headerRows to 1 to include the header row
            widths: ['4%', '6%', '5%', '5%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '6%', '6%', '6%', '6%'],
            body: allRows
          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1 // Add thicker horizontal lines at the first and last row
            },
            vLineWidth: function (i, node) {
              return 1 // Set vertical line width to 1
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'black' : 'gray' // Set color of horizontal lines
            },
            vLineColor: function (i, node) {
              return 'gray' // Set color of vertical lines
            }
          }
        })

        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 8 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 8 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 5]
          },
          hhh: {
              fontSize: 13,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          margin_down: {
            margin: [0, 5, 0, 15]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
