<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_config.training_calendar') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscal_year')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_config.training_calendar') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.org_id">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  {{ $t('elearning_tpm.ac_training_calendar') }} {{ '(' }}{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}{{ ')' }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                          <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length">
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:1%">{{$t('globalTrans.sl_no')}}</b-th>
                                      <b-th style="width:1%">{{$t('elearning_config.training_title')}}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'Days' : 'দিন' }}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'No of Batch' : 'ব্যাচ সংখ্যা' }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jul/' : 'জুলাই/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Aug/' : 'আগস্ট/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Sep/' : 'সেপ্টে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Oct/' : 'অক্টো/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Nov/' : 'নভে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Dec/' : 'ডিসে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jan/' : 'জানু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Feb/' : 'ফেব্রু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Mar/' : 'মার্চ/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Apr/' : 'এপ্রিল/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'May/' : 'মে/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jun/' : 'জুন/' }}{{ fiscalYearEnd() }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(data, index) in datas" :key="index">
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n(index + 1) }}
                                      </b-td>
                                      <b-td style="font-size: 10px">
                                        {{ getTrainingTitle(data.training_title_id) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        <!-- {{ $n(data.no_of_days) }} -->
                                        {{ $n( countDay(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n( getBatch(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 6)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 6) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 6) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 6) }}
                                        {{ durationEnd(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 7)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 7) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 7) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 7) }}
                                        {{ durationEnd(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 8)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 8) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 8) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 8) }}
                                        {{ durationEnd(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 9)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 9) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 9) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 9) }}
                                        {{ durationEnd(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 10)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 10) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 10) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 10) }}
                                        {{ durationEnd(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 11)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 11) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 11) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 11) }}
                                        {{ durationEnd(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 0)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 0) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 0) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 0) }}
                                        {{ durationEnd(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 1)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 1) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 1) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 1) }}
                                        {{ durationEnd(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 2)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 2) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.id, data.training_title_id, 2) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 2) }}
                                        {{ durationEnd(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 3)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 3) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 3) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 3) }}
                                        {{ durationEnd(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 4)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 4) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 4) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 4) }}
                                        {{ durationEnd(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 5)) > 1">
                                        <!-- <span :style="{
                                                      'font-size': '12px',
                                                      'border': '1px solid',
                                                      'position': 'absolute',
                                                      'background-color': 'floralwhite',
                                                      'width': (calculateColspan(data.training_title_id, 5) * 70) + 'px',
                                                      'text-align': 'center'
                                                    }">
                                                    {{ duration(data.training_title_id, 5) }}
                                        </span> -->
                                        {{ durationStart(data.id, data.training_title_id, 5) }}
                                        {{ durationEnd(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="$t('elearning_config.training_calendar') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCalenderFiscalYearWise } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details,
      ListReportHead
    },
    data () {
        return {
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          search: {
            org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
            fiscal_year_id: 0,
            training_type_id: 8
          },
          searchHeaderData: {
            fiscal_year_en: '',
            fiscal_year_bn: '',
            org_en: '',
            org_bn: ''
          },
          loading: false,
          trainingCategoryList: [],
          trainingTitleList: [],
          datas: []
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      monthList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Jan' : 'জানু', text_en: 'Jan', text_bn: 'জানু' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Feb' : 'ফেব্রু', text_en: 'Feb', text_bn: 'ফেব্রু' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Mar' : 'মার্চ', text_en: 'Mar', text_bn: 'মার্চ' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Apr' : 'এপ্রিল', text_en: 'Apr', text_bn: 'এপ্রিল' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'May' : 'মে', text_en: 'May', text_bn: 'মে' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'Jun' : 'জুন', text_en: 'Jun', text_bn: 'জুন' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'Jul' : 'জুলাই', text_en: 'Jul', text_bn: 'জুলাই' },
          { value: 8, text: this.$i18n.locale === 'en' ? 'Aug' : 'আগস্ট', text_en: 'Aug', text_bn: 'আগস্ট' },
          { value: 9, text: this.$i18n.locale === 'en' ? 'Sep' : 'সেপ্টে', text_en: 'Sep', text_bn: 'সেপ্টে' },
          { value: 10, text: this.$i18n.locale === 'en' ? 'Oct' : 'অক্টো', text_en: 'Oct', text_bn: 'অক্টো' },
          { value: 11, text: this.$i18n.locale === 'en' ? 'Nov' : 'নভে', text_en: 'Nov', text_bn: 'নভে' },
          { value: 12, text: this.$i18n.locale === 'en' ? 'Dec' : 'ডিসে', text_en: 'Dec', text_bn: 'ডিসে' }
        ]
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year_id' },
                { key: 'revise_count' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year_id' },
                { key: 'revise_count' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search.fiscal_year_id = this.getCurrentFiscalYearId(this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0))
        this.searchData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      countDay (id) {
      const data = this.datas.find(item => item.training_title_id === id)
      const startDate = new Date(data.training_start_date)
      const endDate = new Date(data.training_end_date)
      endDate.setDate(endDate.getDate() + 1)
      const differenceInMs = endDate - startDate
      const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)
      return differenceInDays
    },
    getTrainingTitle (id) {
      const trainingTitle = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingTitle !== undefined ? trainingTitle.text_bn : ''
      } else {
          return trainingTitle !== undefined ? trainingTitle.text_en : ''
      }
    },
    getBatch (id) {
      const data = this.datas.filter(item => item.training_title_id === id)
      return data.length
    },
    // calculateColspan (id, monthId) {
    //     const month = this.datas.filter(item => {
    //         const date = new Date(item.training_start_date)
    //         return item.training_title_id === id && date.getMonth() === monthId
    //     })

    //     if (month.length === 0) {
    //         return 1
    //     }

    //     const startMonth = new Date(month[0].training_start_date).getMonth()
    //     const endMonth = new Date(month[0].training_end_date).getMonth()

    //     return endMonth - startMonth + 1
    // },
    calculateColspan (id, monthId) {
      const month = this.datas.filter(item => {
        const date = new Date(item.training_start_date)
        const dateEnd = new Date(item.training_end_date)
        return item.training_title_id === id && (date.getMonth() === monthId || dateEnd.getMonth() === monthId)
      })
      if (month.length === 0) {
        return ''
      }
      const startMonth = new Date(month[0].training_start_date).getMonth()
      const endMonth = new Date(month[0].training_end_date).getMonth()
      // return (endMonth - startMonth + 1) * 30 + 'px'
            return (endMonth - startMonth + 1)
    },
    durationStart (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        // const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const startMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_start_date).getMonth() + 1))
        // const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const startMonthData = startMonth ? startMonth.text : null
        // const endMonthData = endMonth ? endMonth.text : null
        return this.$n(startDate) + ' ' + startMonthData
    },
    durationEnd (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_end_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(endDate) + ' ' + endMonthData
    },
    duration (tid, id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            // const dateX = new Date(item.training_end_date)
            // return (item.training_title_id === id && date.getMonth() === monthId) || (item.training_title_id === id && dateX.getMonth() === monthId)
            return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        // const startMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_start_date).getMonth() + 1))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        // const startMonthData = startMonth ? startMonth.text : null
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(startDate) + '-' + this.$n(endDate) + ' ' + endMonthData
    },
//     duration (tid, id, monthId) {
//     // Filter data based on training_title_id and monthId
//     const month = this.datas.filter(item => {
//         const startDate = new Date(item.training_start_date)
//         const endDate = new Date(item.training_end_date)

//         // Check if training_title_id matches and both start and end dates are in the given monthId
//         return (
//             item.id === tid &&
//             item.training_title_id === id &&
//             startDate.getMonth() === monthId &&
//             endDate.getMonth() === monthId
//         )
//     })

//     // Return null if no matching training found
//     if (month.length === 0) {
//         return null
//     }

//     // Retrieve the month name from monthId
//     const monthInfo = this.monthList.find(doc => doc.value === (monthId + 1))
//     const monthName = monthInfo ? monthInfo.text : ''

//     // Initialize an array to store all durations
//     const durations = month.map(item => {
//         const startDate = new Date(item.training_start_date).getDate()
//         const endDate = new Date(item.training_end_date).getDate()

//         // Format each duration as a string and return it
//         return `${this.$n(startDate)} - ${this.$n(endDate)} ${monthName}`
//     })

//     // Join all durations with a comma and space
//     return durations.join(', ')
// },
    duration2 (id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            return (item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        return this.$n(startDate) + '-' + this.$n(endDate)
    },
    fiscalYearStart () {
      const data = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
      const fiscalStart = data.text.split('')
      const fiscalStartYear = fiscalStart[2] + fiscalStart[3]
      return fiscalStartYear
    },
    fiscalYearEnd () {
      const data = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
      const fiscalEnd = data.text.split('')
      const fiscalEndYear = fiscalEnd[7] + fiscalEnd[8]
      return fiscalEndYear
    },
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOrg (id) {
      const sectorObj = this.$store.state.commonObj.organizationProfileList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
      getCurrentFiscalYearId (fiscalYearList) {
            if (fiscalYearList.length === 0) {
              return
            }
            const currentDate = new Date()
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            /** Define the year position  */
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            let currentFiscalYearId = 0
            fiscalYearList.forEach(element => {
              if (element.text_en.indexOf(yearStr) === yearPosition) {
                currentFiscalYearId = element.value
              }
            })
            return currentFiscalYearId
        },
      details (item) {
        this.editItemId = item.id
      },
      getFiscalYear (fisId) {
        const fisYear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.value === parseInt(fisId))
        if (fisYear === undefined) return ''
        return this.$i18n.locale === 'bn' ? fisYear.text_bn : fisYear.text_en
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      // dataChange () {
      //     this.loadData()
      // },
      // async searchData () {
      //     this.loadData()
      // },
      // loadData () {
      //     const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      //     this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      //     RestApi.getData(trainingElearningServiceBaseUrl, trainingCalendarListApi, params).then(response => {
      //         if (response.success) {
      //             this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
      //             this.paginationData(response.data)
      //         }
      //         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      //     })
      // },
      // getCustomDataList (data) {
      //   const listData = data.map(item => {
      //     return Object.assign({}, item)
      //   })
      //   return listData
      // },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      async searchData () {
        this.loading = true
        this.showData = true
        if (this.search.fiscal_year_id) {
          const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingCalenderFiscalYearWise, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          this.loading = false
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          return Object.assign({}, item, orgData, fiscalYearData)
        })
        return listData
      }
    }
}
</script>
